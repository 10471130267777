import React from "react";
import Header from "../components/Header";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { contentEnglish, contentFrench } from "./content";
import ProjectTeam from "../components/ProjectTeam";
import { useStyles } from "../helpers/UseStyles";

export default function HomePage(props) {
  const classes = useStyles();

  let content = {};
  props.language === "fr"
    ? (content = contentFrench["about"])
    : (content = contentEnglish["about"]);

  return (
    <>
      <Header language={props.language} />
      <section>
        <Container maxWidth="md">
          <Box py={10}>
            <Box
              textAlign="center"
              mb={3}
            >
              <Container maxWidth="sm">
                <Box my={5}>
                  <Typography
                    variant="h4"
                    component="h4"
                    color="primary"
                  >
                    {content["header-p2"]}{" "}
                  </Typography>
                </Box>
              </Container>
            </Box>
            <Box className={classes.content}>
              <Typography
                variant="h5"
                component="h5"
                id="information"
              >
                {content["headerGeneral"]}
              </Typography>
              <br />
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                {content["paragraph1"]}
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                {content["paragraph2"]}
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                {content["paragraph3"]}
              </Typography>
              {props.language === "fr" ? (
                <>
                  <Typography
                    align="justify"
                    variant="h5"
                    component="h5"
                  >
                    {content["headerTeam"]}
                  </Typography>
                  <br />
                  <ProjectTeam />{" "}
                </>
              ) : null}
              <br />
              <Typography
                align="justify"
                variant="h5"
                component="h5"
              >
                {content["acknowledgementHeader"]}
              </Typography>
              <br />
              <Typography align="justify">Valentina Fedchenko</Typography>
              <Typography align="justify">Michèle Tauber</Typography>
              <Typography align="justify">Caroline Puaud</Typography>
              <Typography align="justify">Judith Lindenberg</Typography>
              <Typography align="justify">
                Marie-Christine Bornes-Varol
              </Typography>
              <Typography align="justify">Sarah Gimenez</Typography>
              <Typography align="justify">Malena Chinski</Typography>
              <Typography align="justify">Tal Hever-Chybowski</Typography>
              <Typography align="justify">Sharon Bar Kochva</Typography>
              <Typography align="justify">Delphine Bechtel</Typography>
              <Typography align="justify">Fleur Kuhn-Kennedy</Typography>
              <Typography align="justify">Astrid Blees</Typography>
              <Typography align="justify">Shlomo Lerman</Typography>
              <Typography align="justify">Marian Fritsch</Typography>
              <Typography align="justify">Assaf Urieli</Typography>
              <Typography align="justify">Raphael Ralff</Typography>
              <Typography align="justify">Alesa Nekrasova</Typography>
              <Typography align="justify">Gilles Rozier</Typography>
              <Typography align="justify">
                Carole Ksiazenicer-Materon
              </Typography>
              <Typography align="justify">Batia Baum</Typography>
              <Typography align="justify">Anna Angelopoulosv</Typography>
              <Typography align="justify">Nadia Déhan-Rothschild</Typography>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
}
