import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../style/App.css";
import TableHead from "@mui/material/TableHead";
import { Alert } from "@mui/material";
import CSVReport from "./CSVReport";
import {
  createData,
  TablePaginationActions,
  handleTranslations,
} from "../helpers/resultTableHelpers";
import { useEffect } from "react";

export default function ResultTable(props) {
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    setRows([]);
    const res = makeRows();
    setRows(res);
  }, [props.result]);

  const makeRows = () => {
    const data = props.result;
    let lang1 = props.lang1;
    let lang2 = props.lang2;

    for (let i = 0; i < data.length; i++) {
      let finalLang1 =
        lang1 === "yi"
          ? '<div class="rtl secondaryText">'
          : '<div class="secondaryText">';
      let finalLang2 =
        lang2 === "yi"
          ? '<div class="rtl secondaryText">'
          : '<div class="secondaryText">';
      for (let j = 0; j < data[i]["before"]["sentences"].length; j++) {
        finalLang1 += data[i]["before"]["sentences"][j]["text"];
        finalLang1 += " ";
      }
      finalLang2 += handleTranslations(
        data[i]["before"]["translations"][lang2]
      );
      finalLang2 += " ";
      finalLang1 +=
        '<span class="mainSent">' +
        data[i]["result"]["sentences"][0]["text"] +
        "</span>";
      finalLang2 +=
        '<span class="mainSent">' +
        handleTranslations(data[i]["result"]["translations"][lang2]) +
        "</span>";
      finalLang1 += " ";
      finalLang2 += " ";
      for (let k = 0; k < data[i]["after"]["sentences"].length; k++) {
        finalLang1 += data[i]["after"]["sentences"][k]["text"];
        finalLang1 += " ";
      }
      finalLang2 += handleTranslations(data[i]["after"]["translations"][lang2]);
      finalLang2 += " ";
      finalLang1 += "</div>";
      finalLang2 += "</div>";
      let notYiddish = "";
      let yi = "";
      if (lang1 === "yi") {
        yi = finalLang1;
        notYiddish = finalLang2;
      } else {
        yi = finalLang2;
        notYiddish = finalLang1;
      }
      rows.push(createData(i + 1, yi, notYiddish, data[i]["metadata"]));
    }
    return rows;
  };

  const emptyRows =
    props.page > 0
      ? Math.max(0, (1 + props.page) * props.rowsPerPage - rows.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const otherLang = props.lang1 === "yi" ? props.lang2 : props.lang1;
  const languageLabel = otherLang === "fr" ? "Français" : "Русский";

  return (
    <>
      <CSVReport data={rows} />
      {props.result.length === 0 ? (
        <Alert
          style={{ margin: "auto", width: "50%" }}
          severity="info"
        >
          Your query returned no results!
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 500 }}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left"> </TableCell>
                <TableCell align="center">{languageLabel}</TableCell>
                <TableCell align="center">Yiddish</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={`${row.number}_${Math.random()}_${row.french}`}>
                  <TableCell>
                    {row.number + props.page * props.rowsPerPage}
                  </TableCell>
                  <TableCell align="left">
                    <p dangerouslySetInnerHTML={{ __html: row.french }} />
                    <p>
                      <span>
                        <i className="colored">{row.metadata.title}</i>
                      </span>
                      <br />
                      <span>
                        <b className="colored">{row.metadata.author}</b>
                      </span>
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p dangerouslySetInnerHTML={{ __html: row.yiddish }} />
                    <p className="rtl">
                      <span
                        style={{ marginBottom: 0 }}
                        className="rtl"
                      >
                        <i className="colored ">{row.metadata.title}</i>
                      </span>
                      <br />
                      <span>
                        <b className="colored ">{row.metadata.author}</b>
                      </span>
                    </p>
                  </TableCell>
                </TableRow>
              ))}

              {/*{emptyRows > 0 && (*/}
              {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
              {/*    <TableCell colSpan={6} />*/}
              {/*  </TableRow>*/}
              {/*)}*/}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  colSpan={5}
                  count={props.count}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
