import { CSVLink } from "react-csv";
import { Button, Stack } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";

export default function CSVReport({ data }) {
  const regex = /(<([^>]+)>)/gi;
  let rows = [];
  for (let item of data) {
    rows.push({
      number: item["number"],
      french: item["french"].replace(regex, ""),
      yiddish: item["yiddish"].replace(regex, ""),
      author: item["metadata"]["author"].replace(regex, ""),
      title: item["metadata"]["title"].replace(regex, ""),
    });
  }
  let headers = [
    { label: "Number", key: "number" },
    { label: "Français", key: "french" },
    { label: "Yiddish", key: "yiddish" },
    { label: "Author", key: "author" },
    { label: "Title", key: "title" },
  ];
  return (
    <>
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        sx={{ m: 4 }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
          }}
          justifyContent="center"
        >
          <Button
            type="button"
            variant="text"
            sx={{
              width: "30%",
              fontSize: "16px",
              mt: "20px",
            }}
          >
            <CSVLink
              headers={headers}
              data={rows}
              filename="Results.csv"
              style={{ textDecoration: "none" }}
            >
              Download CSV
            </CSVLink>
          </Button>
        </Box>
      </Stack>
    </>
  );
}
